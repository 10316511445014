<template>
  <v-container fluid>
    <div class="from-content">
      <div class="from">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  outlined
                  dense
                  :label="$t('skill.name')"
                  :hint="`${server_errors.skill}`"
                  persistent-hint
                  v-model="editSkill.skill"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-select
                  outlined
                  dense
                  :label="$t('skill.rank')"
                  :hint="`${server_errors.rank}`"
                  persistent-hint
                  v-model="editSkill.rank"
                  :items="rank"
                  item-value="name"
                  item-text="name"
              >
              </v-select>
            </v-col>
            <v-col cols="8">
              <v-btn class="btn-save-change" @click="ValidateForm" :loading="btnLoading">
               {{$t("skill.update")}}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    editSkill: {},
    employee_id: {},
  },
  data() {
    return {
      rank: [
        {
          name: 'poor',
        },
        {
          name: 'medium',
        },
        {
          name: 'good',
        },
        {
          name: 'excellence',
        },
      ],
      btnLoading: false,
      data: {
        skill: "",
        rank: "",
      },
      server_errors: {
        skill: "",
        rank: "",
      }
    }
  },
  methods: {
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true
      }
    },

    saveChange() {
      this.$axios.put(`company/skill/${this.editSkill.id}`, this.editSkill, {
        params: {
          employee_id: this.employee_id,
        }
      }).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$emit('close');
            this.$emit('success');
            this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.btnLoading = false;
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })

    },

  },
  created() {
  }
}
</script>

<style scoped lang="scss">
.from-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .from {
    width: 400px;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
</style>
