<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1><span class="rollback" @click="rollback"><i
            class="fas fa-arrow-circle-left"></i></span>{{ $t("skill.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      :placeholder="$t('Search.search')"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("skill.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listSkill.length > 0">
          <thead>
          <tr>
            <th class="text-left">
              {{ $t("skill.no") }}
            </th>
            <th class="text-left">
              {{ $t("skill.name") }}
            </th>
            <th class="text-left">
              {{ $t("skill.rank") }}
            </th>
            <th class="text-left">
              {{ $t("skill.startDate") }}
            </th>
            <th class="text-left">
              {{ $t("skill.endDate") }}
            </th>
            <th class="text-left">

            </th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in listSkill" :key="idx">
            <td>{{ (pagination.current_page * 10) - 10 + idx + 1 }}</td>
            <td>
              {{ item.skill }}
            </td>
            <td>
              <div class="rank" v-if="item.rank === 'poor'">
                <h6 class="poor">Poor</h6>
              </div>
              <div class="rank" v-if="item.rank === 'medium'">
                <h6 class="medium">medium</h6>
              </div>
              <div class="rank" v-if="item.rank === 'good'">
                <h6 class="good">good</h6>
              </div>
              <div class="rank" v-if="item.rank === 'excellence'">
                <h6 class="excellence">excellence</h6>
              </div>
            </td>
            <td>
              {{ item.created_at }}
            </td>
            <td>
              {{ item.updated_at }}
            </td>
            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else/>
        <Pagination
            class="mt-5 mb-5"
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchEmployeeSkill"
        >
        </Pagination>
        <Loading v-if="isLoading"/>
        <ModalEdit fullscreen title="EditSkill">
          <template v-slot="{close}">
            <edit-skill :editSkill="skill" :employee_id="employee_id" @close="close" @success="fetchEmployeeSkill"/>
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{close}">
            <deleteSkill :empSkillId="empSkillId" :employee_id="employee_id" @close="close"
                         @success="fetchEmployeeSkill"/>
          </template>
        </ModalDelete>
      </div>
    </div>
  </div>
</template>

<script>
import editSkill from "../../../components/skill/editSkill";
import deleteSkill from "../../../components/skill/deleteSkill";
import Pagination from "../../../components/Paginate/Pagination";
import Loading from "../../../components/Loading";
import defaultTableNoResult from "../../../components/defaultTableNoResult";

export default {
  components: {
    editSkill,
    deleteSkill,
    Pagination,
    Loading,
    defaultTableNoResult
  },
  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,

      isLoading: true,
      listSkill: [],
      skill: {},
      empSkillId: "",
      employee_id: this.$route.params.employee_id,
    }
  },
  methods: {
    rollback() {
      this.$router.push({
        name: "manageResume.index"
      })
    },


    searchFilterItem() {
      this.fetchEmployeeSkill();
    },
    filterSkill(empSkillId) {
      return (
          this.listSkill.filter((item) => {
            return item.id == empSkillId;
          })[0] || {}
      )
    },

    onCreate() {
      this.$router.push({
        name: 'skill.create',
        params: {
          employee_id: this.employee_id
        }
      }).catch(() => {
      });
    },

    onEdit(empSkillId) {
      this.skill = {
        ...this.filterSkill(empSkillId)
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(empSkillId) {
      this.empSkillId = empSkillId
      this.$store.commit("modalDelete_State", true);
    },


    fetchEmployeeSkill() {
      this.isLoading = true;
      this.$axios.get(`company/skill`, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          filter: this.searchItem,
          employee_id: this.employee_id
        }
      }).then((res) => {
        if (res.data.code === 200) {
          this.listSkill = res.data.data.data;
          this.pagination = res.data.data.pagination;
          if (!this.listSkill.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        }
        this.isLoading = false;
      }).catch(() => {
      })
    }
  },
  created() {
    this.fetchEmployeeSkill();
  }
}
</script>

<style scoped lang="scss">
.rank {
  font-size: 14px;
  text-transform: capitalize;
  font-family: $font-family;
  margin-top: 15px;
  width: 100px;


  .poor {
    background-color: rgba(58, 227, 116, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
    font-weight: normal;
    border-radius: 30px;
    color: white;
    letter-spacing: 1.5px;
  }

  .medium {
    background-color: rgba(23, 192, 235, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
    font-weight: normal;
    border-radius: 30px;
    color: white;
    letter-spacing: 1.5px;
  }

  .good {
    background-color: rgba(113, 88, 226, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
    font-weight: normal;
    border-radius: 30px;
    color: white;
    letter-spacing: 1.5px;
  }

  .excellence {
    background-color: rgba(255, 56, 56, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
    font-weight: normal;
    border-radius: 30px;
    color: white;
    letter-spacing: 1.5px;
  }
}
</style>
